import TextComponent from './TextComponent'

const Questions = ({ id }) => {
  return (
    <>
      <div className="Questions" id={id}>
        <TextComponent
          header="Kysymyksiä?"
          text="Olkaa yhteydessä Inkaan (0400251704) tai Jukkaan (0504673880)."
          classnames="center smol"
        />
      </div>
    </>
  )
}

export default Questions
