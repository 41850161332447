// import PreloadImage from 'react-preload-image'
// import ij from './assets/inkajukka.jpg'
import './Wedding.css'
import Info from './components/Info'
// import DayCountdown from './components/DayCountdown'
import Nav from './components/Nav'
import Map from './components/Map'
import Timetable from './components/Timetable'
import Gifts from './components/Gifts'
import Dresscode from './components/Dresscode'
import Questions from './components/Questions'
import Instructions from './components/Instructions'
// import Menu from './components/Menu'
import Images from './components/Images'
import ShareImages from './components/ShareImages'
import FormClosed from './components/FormClosed'
import { FaSignOutAlt } from 'react-icons/fa'
import { useNavigate, Navigate } from 'react-router-dom'

const Wedding = () => {
  const navigate = useNavigate()

  const logout = () => {
    localStorage.removeItem('inkajukka-token')
    navigate('/login')
  }

  const loggedIn = !!localStorage.getItem('inkajukka-token')
  return (
    <>
      {!loggedIn &&
        <Navigate to="/login" replace={true} />
      }
      {loggedIn &&
        <div className="Wedding">
          <Nav />
          <header className="Wedding-header">
            <div className="Wedding-header-name">Inka ja Jukka</div>
            <div className="Wedding-header-date">22.07.2023</div>
          </header>
          {/* <DayCountdown /> */}
          <ShareImages id="share-images" />
          <Info id="info" />
          <Map />
          <Timetable id="timetable" />
          <Instructions id="instructions" />
          <Dresscode id="dresscode" />
          <Gifts id="gifts" />
          <Questions id="questions" />
          {/* <PreloadImage className="Wedding-image" src={ij} alt="Ruusut" /> */}
          <FormClosed id="form" />
          {/* <Menu id="menu" /> */}
          <Images id="images" />
          <FaSignOutAlt className="Logout" onClick={logout} />
        </div>
      }
    </>
  )
}

export default Wedding
