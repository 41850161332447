import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import './Login.css'

const Login = () => {
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')
  const [secret, setSecret] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSecret(event.target.value)
    setErrorMessage('')
  }

  const login = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (secret === process.env.REACT_APP_SECRET_WORD) {
      localStorage.setItem('inkajukka-token', 'Token')
      setErrorMessage('')
      navigate('/wedding')
    } else {
      setErrorMessage('Hupsis! Tarkista salasana!')
    }
  }

  return (
    <>
      {localStorage.getItem('inkajukka-token') && (
        <Navigate to="/wedding" replace={true} />
      )}
      {!localStorage.getItem('inkajukka-token') && (
        <div className="Login">
          <h1 className="Login-header">
            Inka <div>♡</div> Jukka
          </h1>
          <div className="Login-form">
            <form onSubmit={login}>
              <input
                type={showPassword ? 'text' : 'password'}
                name="secret"
                onChange={handleFormChange}
                placeholder="Syötä salasana"
                className="Login-password"
                autoComplete="current-password"
              />
              <input type="submit" value="Kirjaudu" className="Login-button" />
            </form>
            <div
              className="Login-showPassword"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? 'Piilota salasana' : 'Näytä salasana'}
            </div>
          </div>
          <div className={`Login-info ${errorMessage && 'error'}`}>
            {errorMessage}
          </div>
        </div>
      )}
    </>
  )
}
export default Login
