import TextComponent from './TextComponent'
import { FiCopy } from 'react-icons/fi'

import './ShareImages.css'

const driveLink = 'https://drive.google.com/drive/u/1/folders/1TJUOUiY8q_hjuhbuUhiwIbykL-cVS_1N'
const email = 'inkajukka@gmail.com'

const copyComponent = (copyText) => <div className='ShareImages-copy' onClick={() => { navigator.clipboard.writeText(copyText) }}><FiCopy /></div>

const ShareImages = ({ id }) => {
  return (
    <>
      <div className="ShareImages" id={id}>
        <TextComponent
          header="Jaa kuvia hääjuhlista"
          text={
            <>
              <p>
                Olisi ihanaa saada kaikki vieraiden ottamat kivat kuvat talteen juhlapäivästä, jee! <br />
              </p>
              <p>
                <b>Voit jakaa kuvia kahdella eri tavalla:</b><br /><br />
                1. Lisää kuvat jaettuun Google Drive -kansioon - <b><a className="TextComponent-link" href={driveLink}>Linkki kansioon!</a></b>{copyComponent(driveLink)}<br /><br />
                2. TAI lähetä kuvat sähköpostilla osoitteeseen <b><a className="TextComponent-link" href="mailto:inkajukka@gmail.com">{email}</a></b> {copyComponent(email)}
              </p>
              <p><i>Jos kuvien jakoon liittyen tulee kysymyksiä, voit ottaa yhteyttä Taruun 0407058615.</i></p>
            </>
          }
          classnames="center smol"
        />
      </div>
    </>
  )
}

export default ShareImages
