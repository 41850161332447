import TextComponent from './TextComponent'
import React from 'react'

const Instructions = ({ id }) => {
  return (
    <>
      <div className="Instructions" id={id}>
        <TextComponent
          header="Saapuminen juhlapaikalle"
          text={
            <>
              Agricolan kirkolta pääsee Kulosaaren Casinolle kätevästi metrolla sekä bussilla. Lisäksi Kulosaaren Casinon edustalla on autopaikkoja, mutta niiden määrä on rajattu. Myös saapuminen omalla veneellä on mahdollista, mutta venekerholta tulee etukäteen tiedustella vieraspaikkaa.
              <h3>Kulosaaren Casinolla</h3>
              Olemme varanneet Kulosaaren Casinolta lasipaviljongin, johon on oma erillinen sisäänkäyntinsä. Varmista, että tulet oikeaan rakennukseen.
              <br />
            </>
          }
          classnames="center smol"
        />
      </div>
    </>
  )
}

export default Instructions
