import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import Login from './Login'
import Wedding from './Wedding'
import './App.css'

const App = () => (
  <Router>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="wedding" element={<Wedding />} />
      <Route path="*" element={<Navigate to="/wedding" />} />
    </Routes>
  </Router>
)

export default App
