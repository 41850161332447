import { NavHashLink } from 'react-router-hash-link'
import { useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { MdClose } from 'react-icons/md'
import { FiMenu } from 'react-icons/fi'

import './Nav.css'

const vw = (v) => {
  var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  return (v * w) / 100
}

const mobileWidth = 1300

const Nav = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > mobileWidth)
  const [navbarOpen, setNavbarOpen] = useState(false)

  const { hash } = useLocation()

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
    const pixels = isDesktop ? 30 : 70
    const yOffset = -(pixels + vw(2))
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
  }

  useEffect(() => {
    if (hash)
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          scrollWithOffset(element)
        }
      }, 500)
  }, [hash])

  const updateMedia = () => {
    setDesktop(window.innerWidth > mobileWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  const navLinks = (
    <>
      <NavHashLink smooth to="#timetable" scroll={(el) => scrollWithOffset(el)}>
        Tietoa juhlista
      </NavHashLink>
      <NavHashLink smooth to="#form" scroll={(el) => scrollWithOffset(el)}>
        Ilmoittautuminen
      </NavHashLink>
      <NavHashLink smooth to="#share-images" scroll={(el) => scrollWithOffset(el)}>
        Jaa hääkuvat
      </NavHashLink>
      <NavHashLink smooth to="#images" scroll={(el) => scrollWithOffset(el)}>
        Kuvia
      </NavHashLink>
    </>
  )

  return (
    <div className="Nav">
      {isDesktop ? (
        <nav className="IsDesktop">{navLinks}</nav>
      ) : (
        <nav className="IsMobile">
          <div
            className={`Button ${navbarOpen ? 'Close' : 'Open'}`}
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            {navbarOpen ? <MdClose /> : <FiMenu />}
          </div>
          <div className={`MobileMenu ${navbarOpen ? 'Show' : 'Hide'}`}>
            {navLinks}
          </div>
        </nav>
      )}
    </div>
  )
}

export default Nav
