import TextComponent from './TextComponent'
import React from 'react'

const Gifts = ({ id }) => {
  return (
    <div className="Gifts" id={id}>
      <TextComponent
        header="Muistaminen"
        text={<>Mikäli haluatte muistaa meitä hääpäivänämme, on mieluisin lahja osallistuminen häämatkamme kustannuksiin. Mahdolliset muistamiset voi ohjata tilillemme FI77 1028 3500 0738 46.</>}
        classnames="center smol"
      />
    </div>
  )
}

export default Gifts
