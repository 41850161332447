import TextComponent from './TextComponent'
import React from 'react'
import './FormClosed.css'

const FormClosed = ({ id }) => {
  return (
    <div className="FormClosed" id={id}>
      <TextComponent
        classnames="center smol"
        header="Ilmoittautuminen"
        text={
          <>
            Häihin ilmoittautuminen on päättynyt. <br />
            Kiitos ilmoittautuneille! <br />
          </>
        }
      />
    </div>
  )
}

export default FormClosed
