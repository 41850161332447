import './TextComponent.css'

const TextComponent = ({ header, text, classnames = '' }) => (
  <div className={`TextComponent ${classnames}`}>
    <header>{header}</header>
    <div className="TextComponent-text">{text}</div>
  </div>
)

export default TextComponent
