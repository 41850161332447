import React, { useEffect } from 'react'
import { FaChurch, FaHotel } from 'react-icons/fa'
import GoogleMapReact from 'google-map-react'
import ReactTooltip from 'react-tooltip'
import './Map.css'

const Marker = ({ altText, icon, toolTipId, url }) => (
  <div className="Map-marker">
    <div data-tip={toolTipId} data-for={toolTipId}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        {icon}
      </a>
    </div>
    <ReactTooltip id={toolTipId}>{altText}</ReactTooltip>
  </div>
)

const Map = () => {
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const agricola = {
    lat: 60.15840423028984,
    lng: 24.939323559839103,
  }

  const kulis = {
    lat: 60.1808179207748,
    lng: 25.00646269909882,
  }

  // There's an open PR to fix the warning with mapId and styles: 
  // https://github.com/google-map-react/google-map-react/pull/1086
  const mapOptions = {
    mapId: '3a84ba44289ffa45',
    disableDefaultUI: true,
    zoomControl: false,
    gestureHandling: 'none',
    draggableCursor: 'default',
    draggingCursor: 'default',
    clickableIcons: false,
    keyboardShortcuts: false,
  }
  return (
    // Important! Always set the container height explicitly
    <div className="Map" style={{ height: '25vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
        defaultCenter={agricola}
        defaultZoom={14}
        disableDefaultUI={true}
        options={mapOptions}
      >
        <Marker
          lat={agricola.lat}
          lng={agricola.lng}
          altText="Mikael Agricolan kirkko"
          toolTipId="kirkko"
          url="https://goo.gl/maps/H7byiuhQ7AtkW3nF7"
          icon={<FaChurch />}
        />
      </GoogleMapReact>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
        defaultCenter={kulis}
        defaultZoom={14}
        disableDefaultUI={true}
        options={mapOptions}
      >
        <Marker
          lat={kulis.lat}
          lng={kulis.lng}
          altText="Kulosaaren Casino"
          toolTipId="juhlat"
          url="https://goo.gl/maps/2sjgHqE1uhsV1hrR7"
          icon={<FaHotel />}
        />
      </GoogleMapReact>
    </div>
  )
}

export default Map
