import TextComponent from './TextComponent'
import React from 'react'

const Dresscode = ({ id }) => {
  return (
    <>
      <div className="Dresscode" id={id}>
        <TextComponent
          header="Pukukoodi"
          text="Suomalaiset kesähäät"
          classnames="center smol"
        />
      </div>
    </>
  )
}

export default Dresscode
