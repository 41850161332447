import './Timetable.css'
import React from 'react'
// import TimetableRowSmall from './TimetableRowSmall'

const Timetable = ({ id }) => {
  return (
    <div className="Timetable smol" id={id}>
      <header>Aikataulu</header>
      <div className="Timetable-row">
        <div className="Timetable-row-title">
          <div className="Timetable-row-time">12:00</div>
          <div className="Timetable-row-desc">
            Siunaustilaisuus, Mikael Agricolan Kirkko
          </div>
        </div>
      </div>
      <div className="Timetable-row">
        <div className="Timetable-row-title">
          <div className="Timetable-row-desc">Omatoiminen kulku juhlapaikalle</div>
        </div>
      </div>
      <div className="Timetable-row">
        <div className="Timetable-row-title">
          <div className="Timetable-row-time">15:00</div>
          <div className="Timetable-row-desc">Juhlat, Kulosaaren Casino</div>
        </div>
        {/* <div className="Timetable-row-text">
          <TimetableRowSmall time="16:00" desc="Ruokailu alkaa" />
          <TimetableRowSmall desc="Puheita" />
          <TimetableRowSmall desc="Ohjelmaa" />
          <TimetableRowSmall time="23:00" desc="Jatkovene lähtee" />
        </div> */}
      </div>
    </div >
  )
}

export default Timetable
