import React, { useState, useEffect } from 'react'
import { ref, listAll, getDownloadURL } from 'firebase/storage'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import TextComponent from './TextComponent'
import { storage } from './config/config'
import './Images.css'

const Images = ({ id }) => {
  const [files, setFiles] = useState()

  useEffect(() => {
    const fetchImages = async () => {
      const urlPromises = []

      // Create a reference under which you want to list
      const listRef = ref(storage, '/images/')

      // Find all the prefixes and items.
      await listAll(listRef)
        .then((res) => {
          res.items.forEach((itemRef) => {
            urlPromises.push(getDownloadURL(itemRef))
          })
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.error('error', error)
        })

      return Promise.all(urlPromises)
    }

    const loadImages = async () => {
      const urls = await fetchImages()
      setFiles(urls)
    }
    loadImages()
  }, [])

  if (!files) return null

  return (
    <div className="Images" id={id}>
      <TextComponent
        header="Kuvia"
        classnames="fullWidth"
        text={
          <Carousel
            infiniteLoop={true}
            dynamicHeight={false}
            showIndicators={false}
            emulateTouch={true}
            showThumbs={false}
            autoPlay={true}
            useKeyboardArrows={true}
          >
            {files
              .sort((a, b) => a.localeCompare(b))
              .map((url, i) => (
                <div key={`haakuva-${i}`}>
                  <img src={url} alt="Hääkuva" className="square" />
                </div>
              ))}
          </Carousel>
        }
      />
    </div>
  )
}

export default Images
