import { FaChurch, FaHotel } from 'react-icons/fa'
import './Info.css'

const Info = ({ id }) => {
  return (
    <div className="Info" id={id}>
      <div className="Info-row Info-double-row">
        <div className="Info-box">
          <FaChurch />
          Kirkko
          <p className="Info-text">
            AGRICOLAN KIRKKO <br />
            Tehtaankatu 23 <br />
            00150 Helsinki
          </p>
        </div>
        <div className="Info-box">
          <FaHotel />
          Juhlat
          <p className="Info-text">
            KULOSAAREN CASINO, <br />
            Hopeasalmenpolku 1 <br />
            00570 Helsinki
          </p>
        </div>
      </div>
    </div>
  )
}

export default Info
